import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "react-intl"
import Img from "gatsby-image"

const Events = (props) => {
  const intl = useIntl()
  const allFilesUsed = props.data.allFile.edges
  console.log(allFilesUsed)
  return (
    <>
      {allFilesUsed.forEach(({ file }) => {
        return console.log(file)
      })}
      <Seo title={intl.formatMessage({ id: "events_title" })} />
      <Layout>
        <h6> ‎ </h6>
        <h1>{intl.formatMessage({ id: "events_text1" })}</h1>
        <p>{intl.formatMessage({ id: "events_text2" })}</p>
        <p>{intl.formatMessage({ id: "events_text3" })}</p>
        <p>{intl.formatMessage({ id: "events_text4" })}</p>
        <p>{intl.formatMessage({ id: "events_text5" })}</p>
        <p>{intl.formatMessage({ id: "events_text6" })}</p>
        <h6> ‎ </h6>
        <Img fluid={props.data.allFile.edges[1].node.childImageSharp.fluid} />
        <h6 style={{ textAlign: "center", color: "#000" }}>
          {intl.formatMessage({ id: "events_photo1" })}
        </h6>

        <div className="embed-container">
          <iframe
            title="video"
            src="https://www.youtube-nocookie.com/embed/iALCNiyWnDM"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        <h6> ‎ </h6>
        <p>{intl.formatMessage({ id: "events_text7" })}</p>
        <h6> ‎ </h6>
        <h2>{intl.formatMessage({ id: "events_text8" })}</h2>
        <h6> ‎ </h6>
        <h3>2022</h3>
        <p>{intl.formatMessage({ id: "events_text011" })}</p>
        <p>{intl.formatMessage({ id: "events_text012" })}</p>
        <p>{intl.formatMessage({ id: "events_text013" })}</p>
        <p>{intl.formatMessage({ id: "events_text014" })}</p>
        <p>{intl.formatMessage({ id: "events_text015" })}</p>
        <p>{intl.formatMessage({ id: "events_text016" })}</p>
        <Img
          fluid={props.data.allFile.edges[3].node.childImageSharp.fluid}
          style={{
            // height: "70%",
            // width: "70%",
            display: "block",
            margin: "0 auto",
          }}
          imgStyle={{ objectFit: "contain" }}
        />

        <h6> ‎ </h6>
        <div className="embed-container">
          <iframe
            title="video"
            src="https://www.youtube-nocookie.com/embed/QLLPm1DhqWc"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        <h6> ‎ </h6>
        <h3>2021</h3>
        <p>{intl.formatMessage({ id: "events_text08" })}</p>
        <p>{intl.formatMessage({ id: "events_text09" })}</p>
        <p>{intl.formatMessage({ id: "events_text010" })}</p>
        <Img
          fluid={props.data.allFile.edges[4].node.childImageSharp.fluid}
          style={{
            // height: "70%",
            // width: "70%",
            display: "block",
            margin: "0 auto",
          }}
          imgStyle={{ objectFit: "contain" }}
        />

        <h6> ‎ </h6>
        <div className="embed-container">
          <iframe
            title="video"
            src="https://www.youtube-nocookie.com/embed/Te2ibHw157M"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        <h6> ‎ </h6>
        <h3>2019</h3>
        <p>{intl.formatMessage({ id: "events_text9" })}</p>
        <p>{intl.formatMessage({ id: "events_text10" })}</p>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({ id: "events_text11" })}</p>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({ id: "events_text12" })}</p>
        <p>{intl.formatMessage({ id: "events_text13" })}</p>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({ id: "events_text14" })}</p>
        <p>{intl.formatMessage({ id: "events_text15" })}</p>
        <p>{intl.formatMessage({ id: "events_text16" })}</p>
        <p>{intl.formatMessage({ id: "events_text17" })}</p>
        <h6> ‎ </h6>
        <Img
          fluid={props.data.allFile.edges[0].node.childImageSharp.fluid}
          style={{
            height: "70%",
            width: "70%",
            display: "block",
            margin: "0 auto",
          }}
          imgStyle={{ objectFit: "contain" }}
        />
        <h6> ‎ </h6>
        <h3>2017</h3>
        <p>{intl.formatMessage({ id: "events_text18" })}</p>
        <p>{intl.formatMessage({ id: "events_text26" })}</p>
        <p>{intl.formatMessage({ id: "events_text27" })}</p>

        <h6> ‎ </h6>
        <Img
          fluid={props.data.allFile.edges[5].node.childImageSharp.fluid}
          style={{
            height: "70%",
            width: "70%",
            display: "block",
            margin: "0 auto",
          }}
          imgStyle={{ objectFit: "contain" }}
        />

        <h6> ‎ </h6>
        <div className="embed-container">
          <iframe
            title="video"
            src="https://www.youtube-nocookie.com/embed/ShOmIbXRi44"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <h6> ‎ </h6>
        <p>{intl.formatMessage({ id: "events_text19" })}</p>
        <p>{intl.formatMessage({ id: "events_text20" })}</p>

        <Img
          fluid={props.data.allFile.edges[2].node.childImageSharp.fluid}
          style={{
            height: "100%",
            width: "70%",
            display: "block",
            margin: "0 auto",
          }}
          imgStyle={{ objectFit: "contain" }}
        />
        <h6> ‎ </h6>

        <h3>2015</h3>
        <p>{intl.formatMessage({ id: "events_text21" })}</p>
        <p>{intl.formatMessage({ id: "events_text22" })}</p>
        <h6> ‎ </h6>
        <h3>2010-2011</h3>
        <p>{intl.formatMessage({ id: "events_text23" })}</p>

        <h6> ‎ </h6>
        <h3>1990</h3>
        <p>{intl.formatMessage({ id: "events_text24" })}</p>
        <p>{intl.formatMessage({ id: "events_text25" })}</p>
      </Layout>
    </>
  )
}

export default Events

export const journalQuery = graphql`
  query journalQuery {
    allFile(filter: { relativeDirectory: { eq: "images/events" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxHeight: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
